<template>
  <div class="box">
    <!-- <div class="img"></div> -->
    <div class="banner jz">
      <!-- <img :src="bannerList[0].bannerImg" alt="" @click="topage(bannerList[0].h5Url)"> -->
      <div v-if="bannerList.length > 0">
        <img
          :src="bannerList[0].bannerImg"
          alt=""
          @click="topage(item.h5Url)"
        />
      </div>
    </div>
    <div class="doc">
      <el-row style="margin: 20px -17px" :gutter="34">
        <el-col :span="5">
          <div class="timg">
            <!-- <img :src="docmsg.avatar" alt=""> -->
            <el-avatar :size="148" :src="docmsg.avatar"></el-avatar>
          </div>
        </el-col>
        <el-col :span="13">
          <el-row class="jsrow">
            <el-col :span="24">
              <div>
                <span class="name">{{ docmsg.doctorName }}</span>
                <span class="label">{{ docmsg.doctorTitle }}</span>
              </div>
            </el-col>
            <el-col :span="24" class="mtop">
              <div class="t2">
                <span>{{ docmsg.hospital }}</span>
                <span>{{ docmsg.department }}</span>
              </div>
            </el-col>
            <el-col :span="24" class="mtop">
              <div class="good">
                <span>擅长：</span>
                <span>{{ docmsg.goodAt }}</span>
              </div>
            </el-col>
            <el-col :span="24" class="mtop">
              <div class="good">
                <a class="xx" @click="xiangxi">详细资料></a>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <div class="toq">
            <!-- <a @click="todown()">向我提问</a> -->
            <el-button type="primary" plain @click="todown()"
              >向我提问</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bg"></div>
    <div class="mbox">
      <el-row>
        <el-col>
          <div
            class="list"
            v-for="(item, index) in title"
            :key="index"
            :class="{ actived: index === currentIndex }"
            @click="itemClick(index)"
          >
            {{ item }}
          </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <!-- <div class="xian"></div> -->
      <el-row style="margin-top:20px; min-height:300px">
        <el-col
          :span="24"
          :offset="1"
          class="elhover"
          style="margin-bottom:10px"
        >
          共{{ Num }}条数据
        </el-col>
        <el-row v-if="videos.length != 0">
          <el-col
            :span="24"
            v-for="project in videos"
            :key="project.pid"
            :offset="1"
            class="elhover"
          >
            <long-videos-listtest
              :videos="project"
              class="now ql"
            ></long-videos-listtest>
          </el-col>
        </el-row>
        <el-empty
          v-else
          description=""
          style="margin-top:100px; width: 100px;
          text-align: center; margin: 0 auto"
        ></el-empty>
      </el-row>
      <!-- <long-videos-list :videos="videos" class="longvideo"></long-videos-list> -->
      <el-row>
        <el-col style="text-align:center; margin: 20px 0">
          <el-pagination
            @size-change="handleSizeChange"
            :hide-on-single-page="value"
            @current-change="handleCurrentChange"
            :page-size="10"
            :current-page="currentPage"
            layout="prev, pager, next, jumper"
            :total="Num"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <bottom-bar class="bottom"></bottom-bar>
  </div>
</template>

<script>
import LongVideosListtest from "components/content/videos/LongVideosListtest";
import BottomBar from "components/content/bottombar/BottomBar";
import { getdoctordetailList, getBanner } from "network/getData";
export default {
  name: "MoreService",
  components: {
    BottomBar,
    LongVideosListtest,
  },
  data() {
    return {
      currentPage: 1,
      title: [],
      currentIndex: 0,
      docmsg: [],
      videos: [],
      options: [],
      value: false,
      Num: 0,
      bannerList: [],
    };
  },
  // computed: {
  //   bsize () {
  //     return { top: (this.videos.length * 110) + 'px' }
  //   }
  // },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  methods: {
    async getBannerList(type) {
      try {
        const result = await getBanner(type);
        this.bannerList = result.data.data;
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    },
    topage(e) {
      if (e !== "empty") {
        window.open(e);
      }
    },
    itemClick(index) {
      this.currentIndex = index;
      this.currentPage = 1;
      const id = this.$route.query.docId;
      this.getdoctordetailListdata(10, 1, Number(id), this.options[index]);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.currentPage = val;
      const id = this.$route.query.docId;
      this.getdoctordetailListdata(
        10,
        this.currentPage,
        Number(id),
        this.options[this.currentIndex]
      );
      window.scrollTo(0, 0);
    },
    xiangxi() {
      sessionStorage.setItem("data", JSON.stringify(this.docmsg));
      this.$router.push({ path: "/mobDoctorIndex" });
      // this.$router.push('/doctordetailmsg/' + this.currentIndex)
    },
    todown() {
      this.$router.push("/moreservice/downapp");
    },
    async getdoctordetailListdata(rows, page, doctorId, infoType) {
      try {
        const result = await getdoctordetailList(
          rows,
          page,
          doctorId,
          infoType
        );
        this.docmsg = result.data.data.doctor;
        this.videos = result.data.data.infoList;
        // console.log(this.docmsg, this.videos)
        if (infoType === "video") {
          this.Num = this.docmsg.videoNum;
        } else if (infoType === "audio") {
          this.Num = this.docmsg.audioNum;
        } else if (infoType === "article") {
          this.Num = this.docmsg.articleNum;
        }
        if (this.Num > rows) {
          this.value = true;
        }
      } catch (error) {
        // console.log(error)
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
  },
  async mounted() {
    let options = [
      { title: "视频", enTitle: "video" },
      { title: "音频", enTitle: "audio" },
      { title: "文章", enTitle: "article" },
    ];
    this.getBannerList("doctor");
    let {
      data: {
        data: {
          doctor: { articleNum, audioNum, videoNum },
        },
      },
    } = await getdoctordetailList(10, 1, this.$route.query.docId, "video");
    // const id = sessionStorage.getItem('id')
    // let { data: data2 } = this.getdoctordetailListdata(
    //   10,
    //   1,
    //   this.$route.query.docId,
    //   "audio"
    // );
    // let { data: data3 } = this.getdoctordetailListdata(
    //   10,
    //   1,
    //   this.$route.query.docId,
    //   "article"
    // );
    let target = [];
    if (videoNum) target.push(options[0]);
    if (audioNum) target.push(options[1]);
    if (articleNum) target.push(options[2]);
    this.title = target.map((item) => item.title);
    this.options = target.map((item) => item.enTitle);
    this.getdoctordetailListdata(
      10,
      1,
      this.$route.query.docId,
      target[0]["enTitle"]
    );
  },
};
</script>

<style scoped>
.box {
  /* position: relative; */
  /* width: 1440px; */
}
.banner {
  /* position: absolute; */
  width: 1440px;
  height: 200px;
  /* left: 120px; */
  /* background-image: url("~assets/banner.png"); */
}
.banner img {
  width: 1440px;
  height: 200px;
}
.img {
  /* position: absolute; */
  width: 1440px;
  /* height: 366px; */
  /* left: 120px; */
  top: -5px;
  background-color: #fafafa;
  /* background-image: url("~assets/banner.png"); */
}
.mbox {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 0px; */
  /* position: absolute; */
  width: 950px;
  /* height: 1254px;
    left: 120px;
    top: 420px; */
  /* background-color: #FAFAFA; */
  margin: 0 auto;
}

.bottom {
  /* position: relative; */
  /* width: 1440px; */
  width: 100%;
  /* height: 594px; */
  left: 0px;
  /* top: 500px; */
  background: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 30px 0px; */
}
.doc {
  /* position: absolute; */
  width: 1200px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 auto;
  margin-bottom: 20px;
}
.timg {
  /* position: absolute; */
  /* width: 156px;
  height: 156px;
  left: 96px;
  top: 20px; */
  width: 148px;
  height: 148px;
  float: right;
}
::v-deep .el-avatar > img {
  height: unset;
  width: 100%;
}
.name {
  font-size: 16px;
  line-height: 16px;
  /* font-family: 'OPPOSans M'; */
}
.label {
  margin-left: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  color: #426eeb;
  font-size: 11px;
  border: 1px solid #426eeb;
}
.t2 {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 350px;
  height: 22px;
  left: 283px;
  font-size: 14px;
  line-height: 22px;
  top: 69px;
  color: #595959;
}
.t2 span {
  margin-right: 10px;
}
.good {
}
.xx {
  /* position: absolute; */
  width: 85px;
  height: 22px;
  left: 283px;
  top: 145px;
  font-size: 14px;
  line-height: 22px;
  color: #f6a609;
}
.toq {
  margin: 0 auto;
  margin-top: 40px;
}
.toq a {
  text-decoration: none;
  /* position: absolute; */
  padding: 16px 20px;
  border-radius: 10px;
  border: 2px solid #f6a609;
  font-size: 20px;
  line-height: 28px;
  color: #f6a609;
}
.toq a:hover {
  background-color: #f6a609;
  color: #ffffff;
}
.titlegroup {
  /* position: absolute; */
  /* width: 500px;
  height: 26px;
  left: 150px;
  top: 16px; */
}
.list {
  display: inline-flex;
  margin-right: 86px;
  cursor: pointer;
}
.actived {
  border-bottom: 3px solid #f6a609;
  /* color: #FFFFFF; */
  /* width: 28px; */
  padding: 0 5px;
  height: 24px;
  color: #f6a609;
  /* border-radius: 4px; */
}
.xian {
  /* position: absolute; */
  width: 950px;
  height: 1px;
  left: 128px;
  top: 55px;
  background: #e8e8e8;
}
.num {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* position: absolute; */
  width: 68px;
  height: 20px;
  left: 154px;
  color: #bfbfbf;
  top: 94px;
}
/* .longvideo{
  width: 950px;
  height: 112px;
  left: 128px;
  top: 134px;
} */
a {
  cursor: pointer;
  text-decoration: none;
}
.el-divider--horizontal {
  margin-top: 12px;
}
.el-col-offset-1 {
  margin-left: 0%;
}
</style>
